<template>
    <n-modal :show="props.show" size="small" @mask-click="onMaskClick">
        <n-card
            style="width: 70vw; max-height: 90vh; overflow-y: auto"
            :title="`[成本事件管理]${nodePath}`">
            <n-data-table
                size="small"
                class="event-table"
                :columns="eventTableColumns"
                :data="relatedEvents"></n-data-table>
            <div class="section">关联已有事件到节点</div>
            <div class="relate-event-panel">
                <div class="ctrl-bar">
                    <div class="label">事件日期：</div>
                    <n-date-picker
                        v-model:value="relateDateRange"
                        size="small"
                        class="input"
                        type="daterange"
                        @confirm="loadRelateSelectEvents"></n-date-picker>
                </div>
                <n-data-table
                    size="small"
                    class="relate-event-table"
                    :columns="relateEventTableColumns"
                    :data="relateEventTableData"
                    :loading="isRelateSelectLoading"
                    :pagination="relateEventTablePagination"
                    @update:page="onPageUpdate"
                    remote></n-data-table>
            </div>
            <div class="section">创建新事件</div>
            <div class="create-event-panel">
                <n-form
                    ref="eventFormRef"
                    :model="eventFormData"
                    :rules="eventFormRules"
                    label-placement="left">
                    <n-form-item label="事件标题" path="title">
                        <n-input
                            v-model:value="eventFormData.title"
                            size="small"></n-input>
                    </n-form-item>
                    <n-form-item label="发生日期" path="date">
                        <n-date-picker
                            v-model:value="eventFormData.date"
                            size="small"></n-date-picker>
                    </n-form-item>
                    <n-form-item label="关联节点" path="target">
                        <span style="width: 100px">{{ props.treeName }} /</span>
                        <n-tree-select
                            v-model:value="eventFormData.target"
                            class="input"
                            :options="treeOpts"
                            size="small"
                            :consistent-menu-width="false"
                            :render-suffix="
                                () =>
                                    h('span', { style: { marginLeft: '30px' } })
                            "
                            multiple
                            virtual-scroll
                            filterable>
                        </n-tree-select>
                    </n-form-item>
                    <n-form-item label="详情链接">
                        <n-input
                            v-model:value="eventFormData.info.detail"
                            size="small"></n-input>
                    </n-form-item>
                </n-form>
                <div class="submit">
                    <n-button
                        type="primary"
                        size="small"
                        @click="onEventCreate"
                        :loading="isEventCreateSubmiting"
                        >提交</n-button
                    >
                </div>
            </div>
        </n-card>
    </n-modal>
    <n-modal v-model:show="isEditEventShow">
        <n-card size="small" style="width: 600px">
            <n-form
                ref="eventEditFormRef"
                :model="eventEditForm"
                label-placement="left">
                <n-form-item label="事件标题" path="title">
                    <n-input
                        v-model:value="eventEditForm.title"
                        size="small"></n-input>
                </n-form-item>
                <n-form-item label="发生日期" path="date">
                    <n-date-picker
                        v-model:value="eventEditForm.date"
                        size="small"></n-date-picker>
                </n-form-item>
                <n-form-item label="详情链接">
                    <n-input
                        v-model:value="eventEditForm.info.detail"
                        size="small"></n-input>
                </n-form-item>
            </n-form>
            <template #action>
                <div style="text-align: right">
                    <n-button
                        type="primary"
                        size="small"
                        @click="onEditEventSubmit"
                        :loading="isEditEventSubmiting"
                        >提交</n-button
                    >
                </div>
            </template>
        </n-card>
    </n-modal>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';

.event-table {
    margin-bottom: 10px;
}

.section {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

.relate-event-panel {
    background-color: @bg-color;
    padding: 10px;
    border: 1px solid #eee;

    .ctrl-bar {
        .common-ctrl-bar;
    }

    .relate-event-table {
        background-color: #fff;
        margin-top: 10px;
    }
}

.create-event-panel {
    background-color: @bg-color;
    padding: 10px;
    border: 1px solid #eee;

    .submit {
        text-align: right;
    }
}
</style>

<script setup>
import { ref, computed, watch, h } from 'vue';
import {
    NModal,
    NCard,
    NDataTable,
    NDatePicker,
    NForm,
    NFormItem,
    NInput,
    NButton,
    NTreeSelect,
    NIcon,
    NTooltip,
    useMessage,
} from 'naive-ui';

import { LinkFilled, LinkOffFilled, EditFilled } from '@vicons/material';
import dayjs from 'dayjs';

import { useNodesStore } from '@/stores/business/Nodes';
import { useUserStore } from '@/stores/global';
import { EventAPI } from '@/common/API';
import Log from '@/common/log';

const nodesStore = useNodesStore();

const message = useMessage();

const userStore = useUserStore();

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    treeName: {
        type: String,
    },
    nodes: {
        type: Array,
    },
    relatedEvents: {
        type: Array,
    },
});

const events = defineEmits(['update:show', 'updateRelatedEvent']);

function onMaskClick() {
    events('update:show', false);
    refreshModel();
}

function refreshModel() {
    relateDateRange.value = null;
    relateEventTableData.value = [];
    relateEventTablePagination.value = genPagination();
    eventFormData.value = genFormData();
}

// related event table

let eventTableColumns = ref([
    {
        title: '事件',
        key: 'title',
    },
    {
        title: '日期',
        key: 'date',
        align: 'center',
        width: 100,
        render: row => dayjs(row.date * 1000).format('YYYY-MM-DD'),
    },
    {
        title: '创建人',
        key: 'creator',
        align: 'center',
        width: 150,
        ellipsis: {
            tooltip: true,
        },
    },
    {
        title: '操作',
        align: 'center',
        width: 80,
        render(row) {
            let isCreator = userStore.currentUser.username === row.creator;
            return (
                <>
                    <NTooltip
                        placement="left"
                        disabled={isCreator}
                        v-slots={{
                            default: () => <div>只允许创建人编辑</div>,
                            trigger: () => (
                                <NButton
                                    size={'tiny'}
                                    quaternary={true}
                                    disabled={!isCreator}
                                    onClick={() => onEventEdit(row)}>
                                    <NIcon>
                                        <EditFilled />
                                    </NIcon>
                                </NButton>
                            ),
                        }}></NTooltip>
                    <NTooltip
                        placement="left"
                        disabled={isCreator}
                        v-slots={{
                            default: () => <div>只允许创建人操作</div>,
                            trigger: () => (
                                <NButton
                                    size={'tiny'}
                                    quaternary={true}
                                    disabled={!isCreator}
                                    onClick={() => onEventLinkOff(row)}>
                                    <NIcon>
                                        <LinkOffFilled />
                                    </NIcon>
                                </NButton>
                            ),
                        }}></NTooltip>
                </>
            );
        },
    },
]);

let nodePath = computed(() => {
    return `${props.treeName}/${props.nodes.join('>')}`;
});

// event relate selector

let relateEventTableColumns = ref([
    {
        title: '事件',
        key: 'title',
    },
    {
        title: '日期',
        key: 'date',
        align: 'center',
        width: 100,
        render: row => dayjs(row.date * 1000).format('YYYY-MM-DD'),
    },
    {
        title: '创建人',
        key: 'creator',
        align: 'center',
        width: 150,
        ellipsis: {
            tooltip: true,
        },
    },
    {
        title: '操作',
        align: 'center',
        width: 60,
        render(row) {
            // let isCreator = userStore.currentUser.username === row.creator;
            // let isBtnDisable =
            //     !isCreator || row.target.includes(nodePath.value);
            let isBtnDisable = row.target.includes(nodePath.value);
            return (
                <>
                    <NTooltip
                        placement="left"
                        disabled={!isBtnDisable}
                        v-slots={{
                            default: () => {
                                {
                                    /* let msg = !isCreator
                                    ? '只允许创建人操作'
                                    : '已关联'; */
                                }
                                return <div>已关联</div>;
                            },
                            trigger: () => (
                                <NButton
                                    size={'tiny'}
                                    quaternary={true}
                                    disabled={isBtnDisable}
                                    onClick={() => onEventLink(row)}>
                                    <NIcon>
                                        <LinkFilled />
                                    </NIcon>
                                </NButton>
                            ),
                        }}></NTooltip>
                </>
            );
        },
    },
]);

function genPagination() {
    return {
        page: 1,
        itemCount: 0,
        pageSize: 5,
        size: 'small',
    };
}

let relateEventTablePagination = ref(genPagination());

let relateEventTableData = ref([]);

let relateDateRange = ref(null);

let isRelateSelectLoading = ref(false);

function loadRelateSelectEvents(value = relateDateRange.value) {
    isRelateSelectLoading.value = true;
    EventAPI.getList(
        value[0] / 1000,
        value[1] / 1000,
        relateEventTablePagination.value.page,
        relateEventTablePagination.value.pageSize
    ).then(res => {
        isRelateSelectLoading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        relateEventTableData.value = res.data.list;
        relateEventTablePagination.value.itemCount = res.data.total;
    });
}

function onPageUpdate(page) {
    relateEventTablePagination.value.page = page;
    loadRelateSelectEvents();
}

function onEventLink(row) {
    Log.click('event_manage_link_event');
    if (row.target.includes(nodePath.value)) {
        return;
    }
    let newTarget = [...row.target];
    newTarget.push(nodePath.value);
    EventAPI.setEventTarget(row.id, newTarget).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        row.target = newTarget;
        message.success('操作完成');
        events('updateRelatedEvent');
    });
}

function onEventLinkOff(row) {
    Log.click('event_manage_link_off_event');
    if (!row.target.includes(nodePath.value)) {
        return;
    }
    row.target = row.target.filter(item => item !== nodePath.value);
    EventAPI.updateEvent(row.id, row).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        message.success('操作完成');
        events('updateRelatedEvent');
    });
}

// event creator

let eventFormRef = ref();

let eventFormRules = ref({
    title: {
        required: true,
        message: '标题不能为空',
        trigger: ['blur', 'submit'],
    },
    date: {
        required: true,
        message: '事件日期不能为空',
        trigger: ['blur', 'submit'],
        type: 'number',
    },
    target: {
        required: true,
        trigger: ['blur', 'submit'],
        validator: () =>
            eventFormData.value.target.length > 0 ||
            new Error('关联节点不能为空'),
    },
});

function genFormData() {
    return {
        title: '',
        date: null,
        target: [],
        info: {
            detail: '',
        },
    };
}

let eventFormData = ref(genFormData());

let isEventCreateSubmiting = ref(false);

let treeOpts = ref([]);

function initTreeNodes() {
    nodesStore.getTreeStructNodes(props.treeName).then(data => {
        treeOpts.value = data;
    });
}

function onEventCreate() {
    Log.click('event_manage_create_event');
    eventFormRef.value.validate(errors => {
        if (errors) {
            return;
        }
        isEventCreateSubmiting.value = true;
        let payload = { ...eventFormData.value };
        payload.date = payload.date / 1000;
        payload.target = payload.target.map(
            item => `${props.treeName}/${item}`
        );
        EventAPI.createEvent(payload).then(res => {
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致操作失败');
                return;
            }
            eventFormData.value = genFormData();
            message.success('创建完成');
            events('updateRelatedEvent');
            isEventCreateSubmiting.value = false;
        });
    });
}

// event edit

let isEditEventShow = ref(false);

let eventEditForm = ref(genFormData());

function onEventEdit(event) {
    eventEditForm.value = { ...event };
    eventEditForm.value.date = eventEditForm.value.date * 1000;
    isEditEventShow.value = true;
}

let isEditEventSubmiting = ref(false);

function onEditEventSubmit() {
    Log.click('event_manage_edit_event');
    let payload = { ...eventEditForm.value };
    payload.date = Math.floor(payload.date / 1000);
    EventAPI.updateEvent(payload.id, payload).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        message.success('操作完成');
        events('updateRelatedEvent');
    });
}

watch(
    () => props.show,
    val => {
        if (val) {
            initTreeNodes();
            // 每次打开时，给创建事件表单关联节点一项设置个默认值
            eventFormData.value.target = [props.nodes.join('>')];
        }
    }
);
</script>
